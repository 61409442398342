const initialState = {
 
  usersTotal: 0,
  companyTotal: 0,
}
export const statistics = (state = initialState, action) => {
  switch (action.type) {
     
    case "STATISTICS": { 
     
      return { ...state, 
        usersTotal:action.usersTotal ,
        companyTotal:action.companyTotal 
      }
    }

  

  
    default: {
      return state
    }
  }
}
export default statistics;
