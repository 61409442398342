import axios from "axios";
// const instance = axios.create({
//   baseURL: "http://localhost:3005/apiv1/",
// });

const instance = axios.create({
  baseURL: "https://linkmindplus.com/apiv1/",
});

export default instance;
