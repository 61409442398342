import { history } from "../../../history"; 

 export const loginWithJWT = (loggedInUser,Inputrole,token) => {
       return dispatch =>   dispatch({
            type: "LOGIN_WITH_JWT",
            token:token,
            payload:loggedInUser,
            role:Inputrole 
       })
}

export const logoutWithJWT = () => {
  return dispatch => {
     dispatch({ type: "LOGOUT_WITH_JWT", payload: {},role:'' })
     history.push(process.env.REACT_APP_BUILD+'/')
  }
} 