import { combineReducers } from "redux"
import customizer from "./customizer/"
import login from "./auth/loginReducer"
import statistics from "./auth/statisticsReducer"
import navbar from "./navbar/Index"
//import User from './user/';

const rootReducer = combineReducers({
  customizer: customizer,
  statistics:statistics,
  login:login,
  navbar: navbar
  //user:User
})

export default rootReducer
 