import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"


function saveToLocalStorage(state){
  try{
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state',serializedState)
  }
  catch(e){
      console.log("SavetoLOCALError",e);
  }
}

function loadFromLocalStorage(){
  try{
    const serializedState = localStorage.getItem('state');
    if(serializedState===null) return undefined
    return  JSON.parse(serializedState)
  }catch(e){
    console.log("LocaltoStore",e);
    
  }

}
const persistedState = loadFromLocalStorage();

const middlewares = [thunk, createDebounce()]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares))
)

store.subscribe(() => saveToLocalStorage(store.getState()))
export  const gettoken = store.getState().login.token;
export  const storeData = store;
export { store }

