import axios from '../axios';
import React, { useState } from 'react';
import { connect } from "react-redux";
import jwt from "jsonwebtoken"
import moment from "moment";

const jwtConfig = {
    "secret"   : "jwtsecrethidearlinkmindworld",
    "expireTime": "120d"
  }
  export const dateFormat = (date) => {
  
    if (date) {
        return moment(date).format("MM-DD-YYYY")
      } else {
        return "";
      }
}
export const dateTimeFormat = (date) => {
  
  if (date) {
      return moment(date).format("L LT")
    } else {
      return "";
    }
}
export const customeName = (text) => {
  
  if (text) {
        text = text.trim();
        let ar = text.split(" ");
        return (ar.length>=2)?ar[0].charAt(0).toUpperCase()+""+ar[1].charAt(0):ar[0].charAt(0).toUpperCase();
    } else {
      return "";
    }
}
export const  capitalize = (text) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return "";
    }
}
export const accountSubscription = (type,status) => {
  type = type ? type.toLowerCase() : "";

  if (type==='lifetime') {
    return "Lifetime";
  } else if (status==='expired') {
    return "Expired";
  } else if (type === 'trial') {
    return "Trial";
  } else if(type==='monthly'){
    return "Paid M";
  } else if (type === 'yearly') {
    return "Paid Y";
  } else {
    return type;
  }
}
export const accountSubscriptionColor = (type,status) => {
  type = type ? type.toLowerCase() : "";
  if (status==='expired' && (type=="trial" || type=="monthly" || type=="yearly" ) ) {
    return "text-danger";
  } else if (type==='trial') {
    return "text-primary";
  } else if (type==='lifetime') {
    return "text-success";
  } else if(type==='monthly'){
    return "text-success";
  } else if (type === 'yearly') {
    return "text-success";
  } else if (type === 'company') {
    return "text-success";
  }else {
    return "text-danger";
  }
}

export const SubscibeStatus = (type) => {
  type = type ? type.toLowerCase() : "";
  if (type==='active') {
    return "text-success";
  } else if (type==='inactive') {
    return "text-warning";
  } else if(type==='expired'){
    return "text-danger";
  } else if (type === 'cancelled') {
    return "text-success";
  }else {
    return "text-danger";
  }
}
export const  adminAuthentication = (accessToken) => {
    if (!accessToken) {
      return false;
    } else {
        try {
            const { id } = jwt.verify(accessToken, jwtConfig.secret)
            return true;
          } catch(err) {
              return false
          }
    }
}  
 
 
 
 