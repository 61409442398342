import axios from '../axios';
import React, { useState } from 'react';
import { connect } from "react-redux";
import * as store from '../../src/redux/storeConfig/store';
 
 

export const   getvalueStatistics = async (token) => {
    let data = store.storeData.getState();
}
export const   getStatistics = async (token) => {
    let axiosHeader = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization':"Bearer "+token
        }
    };
      await axios.post('admin/statistics/countList',
        {},
        axiosHeader
      ).then(response => {
          console.log("HYEE", response);
          let data = response.data.data;
            store.storeData.dispatch({
                type: "STATISTICS",
                usersTotal: data.total_user,
                companyTotal:data.total_enterprise
            })
        return response.data.data;
    }).catch(error=>{
         return error;
    });
}

export const   deleteUser = (token, id) => {
		let axiosHeader = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization':"Bearer "+token
            }
        };
        return axios.post('admin/user/deleteUser',
        	{id:id},
        	axiosHeader
    	).then(response=> {
    		return response.data.message;
        }).catch(error=>{
             return error;
        });
	}

export const  getEnterprise = async (token, id) => {
    let axiosHeader = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization':"Bearer "+token
        }
    };
    return await axios.post('admin/enterprise/getEnterprise',
        {"id":id},
        axiosHeader
    ).then(response=> {
        return response.data;
    }).catch(error=>{
         return {};
    });
}      
export const    getData = (token, search) => {
		let axiosHeader = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization':"Bearer "+token
            }
        };
        return axios.post('enterprise/list',
        	{search,"sortField":"title","sortOrder":"desc","page":1,"limit":200},
        	axiosHeader
    	).then(response=> {
    		return response.data;
        }).catch(error=>{
             return error;
        });
	}   
export const  deleteEnterprise = async (token, id) => {
		let axiosHeader = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization':"Bearer "+token
            }
        };
        return await axios.post('admin/enterprise/delete',
        	{id:id},
        	axiosHeader
        ).then(response => {
            
    		return response.data.message;
        }).catch(error=>{
             return error;
        });
	}       
 
 