import React from "react"
import * as Icon from "react-feather"
const buildUrl = process.env.REACT_APP_BUILD;  
let navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin" ],
    navLink: buildUrl+"/dashboard"
  },
  // {
  //   id: "page2",
  //   title: "Item Management",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: buildUrl+"/page2"
  // },
  {
    id: "userList",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: buildUrl+"/userList"
  },
  {
    id: "trackList",
    title: "Messages Track",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["admin", "editor"],
    navLink: buildUrl+"/track"
  },
  {
    id: "enterprise",
    title: "Company",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin"],
    navLink: buildUrl+"/enterprise"
  }
]

const navigationApprover = [
      /*
      {
        id: "approverproductItem",
        title: "Products Items",
        type: "item",
        icon: <Icon.Gift size={20} />,
        permissions: ["STORE_MANAGER"],
        navLink: buildUrl+"/approver/products"
      } */
    ]


const navigationVendor = [
        {
          id: "vendor-items",
          title: "Vendor Items",
          type: "item",
          icon: <Icon.ShoppingBag size={20} />,
          permissions: ["VENDOR"],
          navLink: buildUrl+"/vendor-items"
        }, 
        {
          id: "vendor-orders",
          title: "Orders",
          type: "item",
          icon: <Icon.ShoppingCart size={20} />,
          permissions: ["VENDOR"],
          navLink: buildUrl+"/vendor-orders"
        } 
    ]


navigationConfig = navigationConfig.concat(navigationVendor)
navigationConfig = navigationConfig.concat(navigationApprover)
export default navigationConfig
